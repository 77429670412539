/* Default styles for the grid container */
/* AgGridTable.css or any other CSS file */
.Modal {
  height:100%;
  max-height:900px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 100%;
  max-width: 900px;
  z-index: 1000;
  overflow: auto;
  display: flex;
  flex-direction: column;
}

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
}

.modal-close-btn {
  margin: auto;
  background: #e74c3c;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  width: 120px; /* Set a fixed width for the button */
  /* float: right; */
  transition: background 0.3s ease, box-shadow 0.3s ease; /* Smooth transition for hover effects */
}

.modal-close-btn:hover {
  background: #c0392b; /* Darker shade of red */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Adds a shadow effect */
}


.ag-grid-container {
    width: 100%;
    height: 650px;
    overflow: auto;
  }
  
  /* Medium screens (tablets) */
  @media (max-width: 768px) {
    .ag-grid-container {
      height: 500px; /* Adjust the height for smaller screens */
      width: 100%; /* Adjust the width for medium screens */
    }
  }
  
  /* Small screens (phones) */
  @media (max-width: 480px) {
    .ag-grid-container {
      height: 350px; /* Adjust the height for very small screens */
      width: 100%; /* Adjust the width for small screens */
    }
  }
  